//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { PrescriptionService } from '@/services/prescription.service';
import { format, fromUnixTime, getUnixTime, parse, getHours, getMinutes, getSeconds, subDays } from 'date-fns';
export default {
  props: {
    prescriptionData: {
      type: Object,

      default() {
        return {};
      }

    }
  },

  data() {
    return {
      showDialog: false,
      alert: {
        show: false,
        message: ''
      },
      processing: false,
      newPrescriptionStartDate: new Date()
    };
  },

  watch: {
    showDialog(val) {
      if (val) {
        this.newPrescriptionStartDate = format(fromUnixTime(this.prescriptionData.timestampsubscriptionstartdate), 'yyyy-MM-dd');
      }
    }

  },
  methods: {
    showAlert(msg) {
      this.alert.show = true;
      this.alert.message = msg;
    },

    dateDisabled(ymd, date) {
      return subDays(new Date(), 1) > date;
    },

    async onChangePrescriptionStartDate() {
      this.alert.show = false;

      try {
        this.processing = true;
        await PrescriptionService.updatePrescriptionStartDate(this.prescriptionData.id, {
          timeStampSubscriptionStartDate: getUnixTime(parse(this.getDateStringWithCurrentTime(this.newPrescriptionStartDate), 'yyyy-MM-dd H:m:s', new Date()))
        });
        this.$emit('refresh-prescriptions');
        this.showDialog = false;
      } catch (err) {
        this.showAlert(err);
      } finally {
        this.processing = false;
      }
    },

    getDateStringWithCurrentTime(dateString) {
      const currentDate = new Date();
      return `${dateString} ${getHours(currentDate)}:${getMinutes(currentDate)}:${getSeconds(currentDate)}`;
    }

  }
};